.resume-container {
    max-width: 100vw;
    min-height: 100vh;
    top: 80px;
    z-index: 1;
    opacity: 1;
    transform-style: preserve-3d;
    background-color: #D6D6D6;
    display: flex;
    flex-direction: column;
}

.text-zone-pages {
    transform: translateY(-20%);
    animation: fadeIn 1s .5s backwards;
}

.title-resume {
    color: #2C2B30;
}

.description-resume{
    margin-top: 20px;
}


.resume-button {   
    height: 43px;
    color: #F58F7C;
    font-family: 'Roboto Flex';
    font-size: 13 px;
    font-weight: 400;
    letter-spacing: 4px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #F58F7C;
    margin-top: 25px;
    float: left;
    transition: background-color 0.5s;

    &:hover {
        background: #F58F7C;
        color: #D6D6D6;
    }
}

.resume-viewer{
    margin-top: 18rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 5%;
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;
}

.resume-pic{
    width: 50vw;

    @media screen and (max-width: 960px) {
        width: 80vw;
    }
}

