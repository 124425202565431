.contact-container {
    max-width: 100vw;
    will-change: contents;
    min-height: 100vh;
    top: 80px;
    opacity: 1;
    background-color: #D6D6D6;
    z-index: 1;
    transform-style: preserve-3d;
}

.text-zone-pages {
    position: absolute;
    left: 10%;
    top: 7%;
    width: 80%;
    transform: translateY(-20%);
    margin: 0;
    animation: fadeIn 1s .5s backwards;
}

.title-contact {
    color: #2C2B30;
}

.description-contact{
    margin-top: 20px;
}

.contact-content{
    position: absolute;
    top: 40%;
    width: 99vw;
    height: 50%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;
}

.email-section {
    width: 100%;
    background-color: #d3d3d3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.phone-section {
    width: 100%;
    background-color: #bdbdbd;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.linkedin-section {
    width: 100%;
    background-color: #9e9e9e;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.github-section {
    width: 100%;
    background-color: #7d7d7d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contact-logo-black {
    margin-top: 1.5rem;
    text-decoration: none;
    text-decoration: none;
    color: #2C2B30;
    font-size: 80px;
    transition: all .8s;
    &:hover{
        transform: translateY(-10%);
    }
}

.contact-logo-white {
    margin-top: 1.5rem;
    text-decoration: none;
    color: #D6D6D6;
    font-size: 80px;
    transition: all .8s;

    &:hover{
        transform: translateY(-10%);
        //color: #2C2B30;
    }
}

.contact-text-black {
    margin-top: 2rem;
    color: #2C2B30;
    letter-spacing: 3px;
    font-size: .75rem;
    margin-bottom: 1.5rem;
    text-decoration: none;
    transition: all .8s;

    &:hover {
        color: #949494;
    }
}

.contact-text-white {
    margin-top: 2rem;
    color: #D6D6D6;
    text-decoration: none;
    letter-spacing: 3px;
    font-size: .75rem;
    margin-bottom: 1.5rem;
    transition: all .8s;

    &:hover {
        color: #2C2B30;
    }
}

