@import 'animate.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Flex', sans-serif;
}



