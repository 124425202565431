.navbar {
    background-color: #2C2B30;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    position: absolute;
    color: #D6D6D6;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Exo 2';
    font-size: 2rem;
    left: 10%;
    transition: all .5s;

    &:hover {
      transform: translateY(-10%);
    }
  }
  
  .fa-display {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 40px;
  }
  
  .nav-links {
    color: #D6D6D6;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #D6D6D6;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #D6D6D6;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: 100%;
      opacity: 1;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      display: none;
      z-index: 998;
      transform: translate3d(0, 0, 0);
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;

      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      position: absolute;
      align-items: center;
      justify-content: center;
      transform: translate3d(0, 0, 0);
      animation: slideInRight .5s backwards;
      z-index: 999;
    }
  
    .nav-item {
        height: 80px;
        transform: translateY(-100%);
    }

    .nav-links {
      text-align: center;
      transform: translate3d(0, 0, 0);
      padding: 2rem;
      height: 20%;
      width: 90vw;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #D6D6D6;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      left: 0;
      bottom: 55%;
      transform: translate(25%, 50%);
      transition: all .5s;

      &:hover {
        transform: translate(25%, 40%);
      }
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #D6D6D6;
      font-size: 2rem;
    }
  
  }

