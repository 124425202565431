.projects-container{
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #D6D6D6;
}

.text-zone-projects{
    padding-top: 3%;
    padding-left: 10%;
    max-width: 90vw;
    animation: fadeIn 1s .5s backwards;
    @media screen and (max-width: 960px) { 
        padding-top: 4rem;
    }
}

.title-projects{
    color: #2C2B30;
}

.projects-description{
    margin-top: 20px;
}

.projects-content {
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 85vw;
    justify-self: center;
    align-self: center;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    grid-gap: 2rem;
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;

    @media screen and (max-width: 960px) {
        padding-top: 15%;

        display: flex;
        flex-wrap: wrap;
    }

}

.project {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    border: 2px solid #2C2B30;
    background-color: #2C2B30;
    border-radius: 1rem;
    overflow: hidden;
}

.website-background {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    background-image: url('./projects_backgrounds/personal-website-background.PNG');
    background-size: 100%;
    transition: all .8s;
    background-position: center center;

    @media screen and (max-width: 960px) {
        background-size: 120%;
    }

    &:hover{
        cursor: pointer;
        background-size: 110%;

        @media screen and (max-width: 960px) {
            background-size: 130%;
        }
    }
}

.ml2-background {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    background-image: url('./projects_backgrounds/ML2-Background.png');
    background-size: 100%;
    transition: all .8s;
    background-repeat: no-repeat;
    background-position: center center;

    @media screen and (max-width: 960px) {
        background-size: 150%;
    }

    &:hover{
        cursor: pointer;
        background-size: 110%;

        @media screen and (max-width: 960px) {
            background-size: 160%;
        }
    }
}

.mastermind-background {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    background-image: url('./projects_backgrounds/mastermind-background.png');
    background-size: 100%;
    background-repeat: no-repeat;
    transition: all .8s;
    background-position: center center;

    @media screen and (max-width: 960px) {
        background-size: 150%;
    }

    &:hover{
        cursor: pointer;
        background-size: 110%;

        @media screen and (max-width: 960px) {
            background-size: 160%;
        }
    }
}

.project-details-inactive {
    width: 90%;
    height: 100%;
    opacity: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.project-details-active {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    animation: fadeIn .5s backwards;
}

.skills-used{
    padding-top: 1rem;
    width: 100%;
    padding-bottom: 1.5rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
    text-decoration: none;
    list-style: none;
}

.project-skill{
    padding: 5% 10% 5% 10%;
    font-size: .7rem;
    font-family: 'Roboto Mono';
    height: 100%;
    background-color: #4f4F51;
    color: #fff;
    letter-spacing: 2px;
    border: 1px solid #000;
    border-radius: .5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: background-color 0.5s;

    &:hover {
        background: #fff;
        color: #4f4F51;
    }
}

.project-description{
    font-size: 1rem;
    letter-spacing: 2px;
    line-height: 20px;
    padding-bottom: 1.5rem;
    color: #fff;
}

.project-name-container {
    width: 100%;
    height: 3.5rem;
    background-color: #fff;
    border-top: 2px solid #2C2B30;

    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.project-name {
    font-size: 1.3rem;
    font-family: 'Exo 2';
    margin-top: auto;
}

.code-button-container{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 2.5rem;
    text-align: center;
}

.code-button{
    padding: 10px 18px;
    color: #F58F7C;
    border: 1px solid #F58F7C;
    text-decoration: none;
    font-family: 'Roboto Flex';
    font-size: 1rem;
    letter-spacing: 2px;
    transition: background-color 0.5s;
    &:hover {
        background: #F58F7C;
        color: #D6D6D6;
    }
}