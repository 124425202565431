html{
    background-color: #2C2B30;
}

.home-container {
    max-width: 100vw;
    will-change: contents;
    min-height: 100vh;
    top: 80px;

    display: flex;
    flex-direction: row;
    opacity: 1;
    overflow: hidden;

    @media screen and (max-width: 960px) { 
        flex-direction: column;
    }
}

.text-and-pic-home{
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-self: center;
    padding-left: 10%;

    @media screen and (max-width: 960px) { 
        align-self: auto;
        padding-left: 0;
    }
}

.text-zone {
    padding-top: 8%;
    padding-left: 10%;
    max-width: 90vw;
    transform: translateY(-20%);
    margin: 0;
    animation: fadeIn 1s .5s backwards;

    @media screen and (max-width: 960px) { 
        padding-top: 8rem;
    }
}



h1 {
    font-size: 53px;
    font-family: 'Exo 2';
    font-weight: 400;
}

.title-home {
    color: #d6d6d6;
    &::before {
        content: '<h1>';
        font-family: 'Roboto Mono';
        color: #d6d6d6;
        font-size: 18px;
        opacity: 0.6;
    }

    &::after {
        content: '<h1/>';
        font-family: 'Roboto Mono';
        color: #d6d6d6;
        font-size: 18px;
        opacity: 0.6;
    }
}

h2 {
    font-family: 'Roboto Flex';
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 3px;
}

.description-home {
    color: #d6d6d6;
    margin-top: 40px;
}

h3 {
    font-family: 'Roboto Flex';
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 3px;
}

.right-text {
    color: #D6D6D6;
    padding-right: 20px;
    text-align: right;
    padding-right: 2rem;
}

.left-text {
    color: #D6D6D6;
    padding-left: 20px;
    text-align: left;
}

.contact-button {
    color: #F58F7C;
    font-family: 'Roboto Flex';
    font-size: 13 px;
    font-weight: 400;
    letter-spacing: 4px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #F58F7C;
    margin-top: 25px;
    float: left;
    transition: background-color 0.5s;

    &:hover {
        background: #F58F7C;
        color: #D6D6D6;
    }
}

.stage-cube-cont-inactive {
    display: none;
}

.stage-cube-cont {
    display: initial;
    width: 50%;
    height: 100%;
    position: relative;
    align-self: center;
    transform: translate(10vw, -20vh);
    animation: fadeIn 1s .5s backwards;
    z-index: 2;

    @media screen and (max-width: 960px) { 
        transform: none;
        padding-bottom: 75%;
    }
  }
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    transform: translate3d(0, 0, 0);
    margin-left: calc(50% - 100px);
    z-index: 1;
  
    div {
      position: absolute;
      z-index: 1;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px #F58F7C;
    }
  
    .face1 {
      transform: translateZ(100px);
      color: #efd81d;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #264de4;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #ea682c;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #2C2B30;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #306998;
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }

/*
.pseudo-navbar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%
  }

.pseudo-navbar-cont {
    top: 0;
    left: 0;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100vw;
}

.pseudo-menu-icon {
    display: none;

    @media screen and (max-width: 960px) { 
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        width: 1.8rem;
        height: 1.8rem;
        pointer-events: all;
    }
}

*/

/*
.right-button {
    color: #D6D6D6;
    margin-left: 25px;
    font-family: 'Roboto Flex';
    font-size: 13 px;
    font-weight: 400;
    letter-spacing: 4px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #D6D6D6;
    float: right;
    transition: background-color 0.5s;

    &:hover {
        background: #D6D6D6;
        color: #fff;
    }
}

.left-button {
    color: #D6D6D6;
    margin-right: 25px;
    font-family: 'Roboto Flex';
    font-size: 13 px;
    font-weight: 400;
    letter-spacing: 4px;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #D6D6D6;
    float: left;
    transition: background-color 0.5s;

    &:hover {
        background: #D6D6D6;
        color: #fff;
    }
}

.banners {
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;
    z-index: 2;
}

.right-banner-text {
    position: absolute;
    right: 15%;
    top: 40%;
    z-index: 999;
}

.left-banner-text {
    position: absolute;
    left: 15%;
    top: 40%;
    z-index: 999;
}

.about-banner {
    width: 100%;
    height: 25%;
    position: absolute;
    top: 70%;
}

.about-banner-back {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(./banner_images/uw-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(40%);
}

.projects-banner {
    position: absolute;
    width: 100%;
    height: 25%;
    top: 95%;
    background-image: url(./banner_images/projects-banner.PNG);
    background-size: cover;
    background-repeat: no-repeat;
}

.resume-banner {
    position: absolute;
    width: 100%;
    height: 25%;
    top: 120%;
}

.resume-banner-back {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(./banner_images/resume-banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(40%);
}
*/
