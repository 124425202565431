.jobs-container {
    height: 100%;
    width: 100%;
    display:flex;

    @media screen and (max-width: 960px) {
        flex-direction: column;
    }
}

.company-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
    justify-content: left;

    @media screen and (max-width: 960px) {
        padding-top: 5%;
        padding-right: 0;
        width: 85%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
}


.job-item{
    white-space: nowrap;
    justify-self: center;
    align-self: center;
    padding: 1rem 1rem;
    color: #9e9e9e;
    border-left: 3px solid #9e9e9e;
    transition: background-color 0.5s;
    width: 100%;

    @media screen and (max-width: 960px) {
        border-left: none;
        border-bottom: 3px solid #9e9e9e;
        width: auto;
    }

    &:hover {
        cursor: pointer;
        color: #D6D6D6;
        background-color: #9e9e9e;
        border-left: 3px solid #2C2B30;
        
        @media screen and (max-width: 960px) {
            border-left: none;
            border-bottom: 3px solid #2C2B30;
            width: auto;
        }
    }
}

.item-active {
    white-space: nowrap;
    justify-self: center;
    align-self: center;
    padding: 1rem 1rem;
    color: #D6D6D6;
    background-color: #9e9e9e;
    border-left: 3px solid #2C2B30;
    transition: background-color 0.5s;
    width: 100%;

    @media screen and (max-width: 960px) {
        border-left: none;
        border-bottom: 3px solid #2C2B30;
        width: auto;
    }

    &:hover {
        cursor: pointer;
    }
}

.item-inactive {
    white-space: nowrap;
    justify-self: center;
    align-self: center;
    padding: 1rem 1rem;
    color: #9e9e9e;
    border-left: 3px solid #9e9e9e;
    background-color: transparent;
    transition: background-color 0.5s;
    width: 100%;

    @media screen and (max-width: 960px) {
        border-left: none;
        border-bottom: 3px solid #9e9e9e;
        width: auto;
    }

    &:hover {
        cursor: pointer;
        color: #D6D6D6;
        background-color: #9e9e9e;
        border-left: 3px solid #2C2B30;
        
        @media screen and (max-width: 960px) {
            border-left: none;
            border-bottom: 3px solid #2C2B30;
            width: auto;
        }
    }
}

.job-title {
    font-family: 'Roboto Mono';
    font-size: 1.8rem;
    width: 85%;
    animation: fadeIn 1s .5s backwards;

    @media screen and (max-width: 960px) {
        text-align: center;
        padding-top: 2rem;
    }
}

.job-company {
    font-family: 'Roboto Mono';
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
    width: 85%;
    color: #4f4F51;

    @media screen and (max-width: 960px) {
        text-align: center;
    }
}

.job-description {
    width: 60%;
    font-family: 'Roboto Flex';
    letter-spacing: 1px;
    line-height: 1.5rem;

    @media screen and (max-width: 960px) {
        width: 85%;
    }
}