.about-container {
    max-width: 100vw;
    min-height: 100vh;
    top: 80px;
    opacity: 1;
    background-color: #D6D6D6;
    z-index: 1;
    transform-style: preserve-3d;
    display: grid;
    grid-template-columns: repeat(1, auto-fit);
    grid-template-rows: repeat(4, auto-fit);
    transition: height 0.66s ease-out;
    overflow: hidden;
}

.title-about{
    animation: fadeIn 1s .5s backwards;
    @media screen and (max-width: 960px) { 
        padding-bottom: 1rem;
    }
}

.text-zone-about {
    padding-top: 8%;
    padding-left: 10%;
    transform: translateY(-20%);
    @media screen and (max-width: 960px) { 
        padding-top: 4rem;
    }
}

.txt-and-pic {
    padding-bottom: 3rem;
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, auto));
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;
    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(auto-fit, minmax(95vw, auto));

    }
}

.about-text {
    padding-left: 20%;
    text-align: left;
    color: #2C2B30;
    max-width: 90vw;
    font-size: 1rem;
    letter-spacing: 2px;
    line-height: 27px;
    padding-bottom: 12%;
    @media screen and (max-width: 960px) { 
        padding-left: 10%;
    }
}

.about-pic {
    width: 40%;
    justify-self: center;
    align-self: top;

    @media screen and (max-width: 960px) {
        padding-left: 0;
        width: 70%;
        padding-bottom: 10%;
        align-self: center;
    }
}

h4 {
    font-family: 'Exo 2';
    font-size: 40px;
    font-weight: 400;
}

.skills{
    padding-left: 10%;
    max-width: 85vw;
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;
    padding-bottom: 6rem;
}

.skills-content{
    display: grid;
    padding-top: 1%;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
    text-decoration: none;
    list-style: none;
}

.skill{
    padding: 5% 10% 5% 10%;
    font-size: .7rem;
    font-family: 'Roboto Mono';
    height: 100%;
    background-color: #D6D6D6;
    color: #4f4F51;
    letter-spacing: 2px;
    border: 2px solid #4f4F51;
    border-radius: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all .5s;

    &:hover {
        background: #9e9e9e;
        color: #d6d6d6;
        transform: translateY(-10%);
        border: 2px solid #4f4F51;
    }
}

.work-exp{
    padding-top: 0;
    max-width: 90vw;
    padding-left: 10%;
    padding-bottom: 20%;
    animation: fadeIn 1s .5s backwards;
    animation-delay: 1s;
}

.skills-heading{
    padding-bottom: 2%;
    width: 80%;
    @media screen and (max-width: 960px) { 
        padding-bottom: 10%;
    }
}

.easter-egg {
    opacity: 0;
}